
<template>
  <div>
    <eden-page-header :title="'MFCs'" />
    <eden-page-stats :title="'Overview'" :loading="summary.loading" :stats="stats" :show-periods="true" :periods="periods"
      @set-period="getOrdersSummary" />
    <eden-loader v-if="loading" />
    <el-tabs v-else v-model="tab" @tab-click="updateRouteQuery">

      <el-tab-pane label="Operations" name="operations">
        <operations-orders />
      </el-tab-pane>
      <el-tab-pane label="Food Production Orders" name="food-production">
        <food-production-orders />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
  
<script>
import orders from "@/requests/orders";
import * as actions from "@/store/action-types";

export default {
  name: "MFC",
  components: {
    OperationsOrders: () =>
      import(
        "@/components/MFC/OperationsOrders"
      ),
    FoodProductionOrders: () =>
      import(
        "@/components/MFC/FoodProductionOrders"
      ),
  },
  data() {
    return {
      loading: false,
      tab: "operations",
      period: "today",
      periods: {
        today: "Today",
        tomorrow: "Tomorrow",
        thisweek: "This week",
      },
      summary: {
        loading: false,
        data: {},
      },
    };
  },
  computed: {
    stats() {
      const summary = this.summary.data;
      return [
        {
          label: "All time orders",
          figure: this.formatFigure(summary.total_orders_alltime),
        },
        {
          label: `Orders ${this.period}`,
          figure: this.formatFigure(summary.total_orders),
        },
        {
          label: `One-time orders ${this.period}`,
          figure: this.formatFigure(summary.total_one_time_orders),
        },
        {
          label: `Pendings ${this.period} orders`,
          figure: this.formatFigure(summary.total_one_time_order_not_marked_as_ready),
        },
        {
          label: "Completed orders",
          figure: this.formatFigure(summary.total_orders_completed),
        },
        {
          label: "Ready orders",
          figure: this.formatFigure(summary.total_one_time_order_marked_as_ready),
        },
      ];
    },
    services() {
      return this.$store.getters.services;
    },
  },
  created() {
    this.getOrdersSummary({ period: this.period });
    this.loading = true;
    this.$store.dispatch(actions.GET_SERVICE_PARTNERS_LIST).then(() => {
      this.loading = false;
    });
    let { t } = this.$route.query;
    this.tab = t ? t : "operations";
  },
  methods: {
    updateRouteQuery(tab) {
      const { t } = this.$route.query;
      if (t === this.tab) {
        return false;
      }
      this.$router.push({
        name: "fulfillment.index",
        query: { t: tab.name },
      });
    },
    getOrdersSummary({ period, from, to }) {
      this.summary.loading = true;
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });

      orders
        .summary(start_date, end_date)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.summary.data = data;
            this.period = period;
          }
          this.summary.loading = false;
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    getOrders() {
      this.loading = true;
      this.summary.loading = true;

      setTimeout(() => {
        this.loading = false;
        this.summary.loading = false;
      }, 1500);
    },
  },
};
</script>
  